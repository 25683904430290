/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "ngx-toastr/toastr";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: source-Sans-bold;
    src: url("assets/font/Source_Sans_Pro/SourceSansPro-Bold.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: source-Sans;
    src: url("assets/font/Source_Sans_Pro/SourceSansPro-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Roboto-Regular;
    src: url("assets/font/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Roboto-Bold;
    src: url("assets/font/Roboto/Roboto-Bold.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Raleway-Medium;
    src: url("assets/font/Raleway/static/Raleway-Medium.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Raleway-Bold;
    src: url("assets/font/Raleway/static/Raleway-Bold.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: bentonsans-Regular;
    src: url("assets/font/Benton-Sans/bentonsansmedium/BentonSansMedium.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: bentonsans-Regular;
    src: url("assets/font/Benton-Sans/bentonsanscompregular/BentonSansCompRegular.otf");
    font-weight: 400;
    font-style: normal;
}

body {
    height: 100%;
    margin: 0;
    font-family: Roboto-Regular;
    color: #1a1919;
    background-color: #f7f8fa;
    overflow-x: hidden;
}

.inmar-button {
    width: auto;
    height: 38px;
    background-color: #03878c !important;
    font-family: bentonsans-Regular;
    color: #ffffff !important;
    font-size: 15px;
    margin-right: 0 !important;
    padding: 9px 16px;
    text-transform: lowercase;
    display: inline-block;
}

// .lower-transform {
//     text-transform: lowercase;
// }

.inmar-button2 {
    width: auto;
    height: 38px;
    background-color: #03878c;
    font-family: bentonsans-Regular;
    color: #ffffff;
    font-size: 15px;
    margin-right: 0 !important;
    padding: 9px 16px;
    // text-transform: lowercase;
    display: inline-block;
}

.inmar-button-outline {
    width: auto;
    height: 38px;
    background-color: #ffffff;
    font-family: 'Roboto';
    color: #03878c !important;
    font-size: 15px;
    border: 1px solid #03878c !important;
    line-height: 25px;
    text-align-last: end;
    margin-right: 0 !important;
    padding: 8px 16px;
    text-transform: lowercase;
    display: inline-block;
}

.inmar-button::first-letter,
.inmar-button-outline::first-letter {
    text-transform: capitalize;
}

.inmar-button:hover,
.inmar-button-outline:hover {
    box-shadow: 0px 2px 6px #03878C29;
}

// .dropdown img.float-end {
//     transform: rotate(0deg) !important;
//     color: #03878C;
// }

// .show.dropdown img.float-end {
//     transform: rotate(180deg) !important;
// }

.inmar-button-outline::after,
.inmar-button::after {
    display: none;
}

.inmar-button-outline img.float-start,
.inmar-button img.float-start {
    margin-right: 8px;
}

.inmar-button-outline img:last-child,
.inmar-button img:last-child {
    margin-left: 8px;
}

.inmar-button-outline img:last-child,
.inmar-button-outline img.float-start {
    margin-top: 0.30rem !important;
}

.inmar-button img:last-child {
    margin-top: 0.15rem !important;
}

/* ---Praveen Changes Ends---*/

// .inmar-button:hover {
//     color: #ffffff;
// }

.dropdown-toggle::after {
    opacity: 0;
}

.dropdown-item {
    font-size: 13px;
    font-family: Roboto-Regular;
}

/*praveen changes Start*/
.dropdown-item:focus {
    background-color: #e6f3f4;
}

.dropdown-item:hover {
    background-color: #DEDCE4;
}

/*Praveen changes End */

.form-control:focus {
    box-shadow: none;
    border: 1px solid #1a1919;
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
}

.toast {
    opacity: 1 !important;
}

.fixed-top {
    z-index: 950;
}

.grid-header-text {
    font-family: bentonsans-Regular !important;
    color: #03878c;
    font-size: 24px;
    font-weight: bold !important;
}

.modal-header-text {
    font-family: Roboto-Bold !important;
    font-size: 18px;
}

.grid-sub-header-text {
    color: #75737b;
    font-family: Roboto-Regular;
    font-size: 14px;
    opacity: 0.8;
}

.grid-sub-header-value {
    font-family: bentonsans-Regular !important;
    font-size: 22px;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    cursor: pointer;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dedce4;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #dedce4;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #dedce4;
}

.k-grid-content .k-virtual-content ::-webkit-scrollbar {
    cursor: pointer;
}

.mat-tab-label {
    height: 40px !important;
}

.mat-tab-group .mat-ripple-element {
    transition-duration: 0ms !important;
    background: transparent !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #f5f5f5;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #f5f5f5;
}


.module-title,
.kendo-popup .kp-body .kp-body-title,
.kp-status-heading,
.nav-container-1 .mat-tab-label-active .mat-tab-label-content,
.nav-container-4 .tab-container .mat-tab-label,
.nav-container-4 .tab-container .mat-tab-link,
.nav-container-4 .tab-container .view-label,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label,
.nav-container-5 .mat-tab-label-active .mat-tab-label-content,
.nav-container-5 .mat-tab-label-active .mat-tab-label-content {
    font-family: Roboto-Regular !important;
    font-weight: normal;
    font-size: 13px !important;
}

.mat-tab-label .mat-tab-label-content {
    font-weight: 500 !important;
    font-size: 14px !important;
}

/*  nav container 1 - starts */

.nav-container-1 {
    background-color: #ffffff;
}

.nav-container-1.inner-content {
    margin: 0 0 0 -20px;
}

.nav-container-1.inner-content .header .highlighted {
    color: #777777;
    background: #dae9f1;
    border-radius: 5px;
    padding: 5px;
}

.nav-container-1.inner-content .content.border {
    border: 1px solid #1367a3 !important;
}

.nav-container-1.inner-content .row {
    border-bottom: none;
}

.nav-container-1 .row {
    margin: 0;
}

.nav-container-separator {
    margin: 0 -20px;
}

.nav-container-1.inner-content .row {
    border-bottom: none;
}

.nav-container-1.inner-content .mat-tab-header {
    border-bottom: 1px solid #1367a3 !important;
}

.mat-tab-header {
    border-bottom: 0 !important;
}

.nav-container-1 .tab-container,
.nav-container-1 .icons-container {
    padding: 0;
}

.tab-container .mat-tab-label {
    padding: 0px 8px;
    justify-content: left;
    min-width: 0;
    margin-left: 15px;
    opacity: 1;
    height: 40px;
    font-size: 14px;
}

.tab-container .mat-tab-label:last-child {
    margin-right: 0;
}

.tab-container .mat-tab-label span {
    background-color: #ffffff;
    color: #333333;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 10px;
    font-family: Roboto-Regular !important;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #c3c3c3;
    font-weight: normal;
}

.tab-container .mat-tab-label-active span {
    background-color: #1367a3 !important;
    color: #ffffff;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 10px;
    border: 1px solid #1367a3;
}

.tab-container .mat-tab-label:hover {
    color: #0F172A;
    background: #F2F2F2;
}

.tab-container .mat-tab-label-active {
    color: #303584 !important;

}

.tab-container .mat-tab-label {
    color: #757575;
}

.tab-container .mat-tab-label-active .mat-tab-label-content {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.nav-container-1 .mat-ink-bar {
    background-color: #1367a3 !important;
    border-radius: 10%;
    height: 4px !important;
}

.nav-container-1 .icons-container {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.nav-container-1 .icons-container i {
    margin-left: 20px;
    cursor: pointer;
}


.nav-container-1 .mat-tab-label .mat-tab-label-content {
    padding-bottom: 5px;
}

.mat-tab-group .mat-ripple-element {
    transition-duration: 0ms !important;
    background: transparent !important;
}

.nav-container-1 .custom-tab-group .mat-ink-bar {
    background-color: transparent !important;
}

.nav-container-1 .custom-tab-group .mat-tab-label .mat-tab-label-content {
    padding-bottom: 0;
}

.nav-container-1 .mat-tab-label.mat-tab-label-active {
    background-color: #fff !important;
}

.mat-tab-label-active {
    opacity: 1 !important;
}


/*  nav container 1 - starts */

.nav-container {
    margin: 0 -20px;
    background-color: #ffffff;
}

.nav-container.inner-content {
    margin: 0 0 0 -20px;
}

.nav-container.inner-content .header .title {
    font-family: Roboto-Bold;
}

.nav-container.inner-content .header .highlighted {
    color: #777777;
    border-radius: 5px;
    padding: 5px;
}

.nav-container.inner-content .content.border {
    border: 1px solid #1367a3 !important;
}

.nav-container.inner-content .row {
    border-bottom: none;
}

.nav-container.inner-content .mat-tab-header {
    border-bottom: 1px solid #1367a3 !important;
}

.nav-container .row {
    margin: 0;
    border-bottom: 0px solid #f5f5f5;
}

.nav-container-separator {
    margin: 0 -20px;
    border-bottom: 1px solid #1367a3;
}

.nav-container.inner-content .row {
    border-bottom: none;
}

.nav-container.inner-content .mat-tab-header {
    border-bottom: 1px solid #1367a3 !important;
}

.mat-tab-header {
    border-bottom: 0 !important;
}

.nav-container .tab-container,
.nav-container .icons-container {
    padding: 0;
}

.tab-container .mat-tab-label:last-child {
    margin-right: 0;
}

.tab-container .mat-tab-label span {
    background-color: #ffffff;
    color: #333333;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 10px;
    border: 1px solid #c3c3c3;
    font-weight: normal;
}

.tab-container .mat-tab-label-active span {
    background-color: #1367a3 !important;
    color: #ffffff;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 10px;
    border: 1px solid #1367a3;
}

.nav-container .mat-ink-bar {
    background-color: #ffc20e !important;
    height: 5px !important;
    font-weight: bold;
}

.nav-container .icons-container {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.nav-container .icons-container i {
    margin-left: 20px;
    cursor: pointer;
}

.nav-container .mat-tab-label .mat-tab-label-content {
    padding-bottom: 5px;
}

.mat-tab-group .mat-ripple-element {
    transition-duration: 0ms !important;
    background: transparent !important;
}

.nav-container .custom-tab-group .mat-ink-bar {
    background-color: transparent !important;
}

.nav-container .custom-tab-group .mat-tab-label .mat-tab-label-content {
    padding-bottom: 0;
}

/*  nav container 1 - ends */

.k-grid-header {
    background-color: #f4f5f5;
    font-family: Roboto-Regular;
    font-size: 14px;
    border: 1px solid #e9ecf4;
    color: #1a1919;
}

.k-grid-header .k-header {
    vertical-align: top !important;
    border-right-width: 1px;
}

.k-grid td {
    border-width: 0px;
    border-bottom-width: 1px;
}

.k-grid th {
    border-width: 0px;
}

.kendo-th-grid-header {
    white-space: normal;
    text-align: center !important;
}

.development-img {
    width: 60%;
    height: 400px;
}

.payment-table-layout.k-grid td {

    font-family: Roboto-Regular;
    font-size: 12px !important;
    padding: 2px 10px !important;

}

@media screen and (min-width: 1450px) and (max-width:2300px) {

    kendo-grid.payment-table-layout.k-widget.k-grid.k-grid-lockedcolumns.ng-star-inserted {
        height: 700px !important;
    }
}

.payment-table-layout.k-grid {
    border-radius: 8px 8px 8px 8px;
}

.k-grid-table {
    width: 100%;
}

.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked .k-header,
.k-grid .k-grid-content-locked td {
    border-color: #dedce4;
}

.k-grid .k-grid-header-locked .k-header {
    border-right-width: 0px;
}

.modal-header {
    background-color: #f2f0f0;
    color: #141414;
    font-weight: bold;
}

.mat-dialog-container {
    padding: 0% !important;
    overflow-y: auto;
}

.mat-icon {
    cursor: pointer;
}

.messagebox-popup .mat-dialog-container {
    height: calc(100vh - 65px) !important;
    width: calc(100vw - 10px) !important;
    margin-top: 45px;
    background: #f7f8fa;
    z-index: 9999;
    overflow: hidden;
    margin-left: 0%;
    margin-right: 2%;
    width: 97%;
}

.split-invoice-popup .mat-dialog-container {
    width: 97%;
    overflow-x: hidden;
    background-color: #f7f8fa;
    height: 90vh !important;
    min-height: 90vh;
    max-height: 100vh !important;
    z-index: 1500;
    border-radius: 8px;
    margin-left: 2%;
    margin-right: 2%;
}

// .messagebox-popup .mat-dialog-content {
//     max-height: 100vh !important;
// }

.dialog-Disputepopup .mat-dialog-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 65vh;
    max-height: 80vh;
    z-index: 1500;
    border-radius: 8px;
}

.addpromotion-popup .mat-dialog-container {
    width: 97vw;
    height: 80vh;
    overflow: hidden !important ;
    z-index: 1500;
    border-radius: 8px;
}

.dialog-Splitinvoicepopup .mat-dialog-container {
    width: 376px;
    height: 273px !important;
    background-color: #f7f8fa;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DEDCE4;
}

.applyCash-dialog-Splitinvoicepopup .mat-dialog-container {
    width: 376px;
    height: 126px !important;
    background-color: #f7f8fa;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DEDCE4;
}

.dialog-Split-invoice-popup .mat-dialog-container {
    overflow: hidden !important;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DEDCE4;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 78vh;
    max-height: 80vh;
}

.messagebox-popup {
    overflow: auto;
    max-width: 100% !important;
    margin-left: 2%;
}

.split-invoice-popup {
    overflow: hidden;
    max-width: 100% !important;
}

.file-upload-text {
    font-size: 12px;
    color: #303584;
    text-decoration: underline;
    cursor: pointer;
}

.form-control {
    box-sizing: border-box;
    border-radius: 10px !important;
    /* height: 52px; */
    color: #b0b3b7;
}

.dispute-form {
    height: 36px;
}

.label-class {
    font-family: bentonsans-Regular;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2f3044;
}

.form-control:focus {
    background-color: #ffffff !important;
}

.missing-text {
    color: #d80b0b;
    font-weight: 600;
}


/* ::ng-deep .k-grid tr.k-state-selected > td {
    background-color: rgba(3, 155, 229, 0.25) !important;
} */

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
    background-color: #e6f3f4 !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    border: 1px solid #1a1919;
    color: #1a1919;
    background-color: #e6f3f4;
    border-radius: 5px;
}

.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate,
.k-checkbox.k-indeterminate {
    color: #1a1919;
    background-color: #e6f3f4;
    border: 1px solid #1a1919;
}

.k-checkbox:checked,
.k-checkbox.k-checked.k-state-focus {
    box-shadow: none !important;
}

.k-alt,
.k-pivot-layout>tbody>tr:first-child>td:first-child {
    background-color: #ffffff !important;
}

.k-grid tr.k-alt {
    background-color: #ffffff !important;
    border: 1px solid black;
}


.k-grid-header .k-grid-filter {
    .k-icon {
        opacity: 0;
    }
}

.k-grid-header .k-grid-filter:hover {
    .k-icon {
        opacity: 1;
    }
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
    color: #03878c;
}

.k-columnmenu-item.k-state-selected {
    color: white;
    background-color: #03878c;
}

.k-button-primary,
.k-button.k-primary {
    border-color: #03878c;
    color: white;
    background-color: #03878c;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.04));
}

.k-button-primary:hover,
.k-button.k-primary:hover,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary {
    border-color: #03878c;
    background-color: #03878c;
}

.k-grid-header .k-grid-filter.k-state-active,
.k-grid-header .k-header-column-menu.k-state-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-state-active {
    background-color: #d6d5d5;

    .k-icon {
        opacity: 1;
    }
}

// .cdk-overlay-container .mat-dialog-container,
// .cdk-overlay-container .mat-dialog-content {
//     overflow: scroll;
// }

.k-animation-container {
    margin-top: -6.5em;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
    .ng-select .ng-select-container {
        border: 1px solid #ccc !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    .ng-select .ng-select-container {
        border: 1px solid #ccc;
    }
}

.ng-select .ng-select-container {
    border: none;
}

.ng-select .ng-select-container:hover {
    border: 1px solid #ccc;
    cursor: pointer;
}

.customer-option .ng-select .ng-select-container {
    border: none;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}

.document-upload {
    color: #007CBB;
    cursor: pointer;
}

.kendo_file_upload.k-actions,
.kendo_file_upload.k-edit-buttons,
.kendo_file_upload.k-action-buttons,
.kendo_file_upload.k-columnmenu-actions {
    display: none;
}

.avatar {
    vertical-align: middle;
    border-radius: 50%;
    background-color: #ffffff;
}

.kendo_file_upload>.k-action-buttons {
    display: none;
}

.k-pager-numbers .k-link.k-state-selected {
    color: #03878c;
    background-color: #e6f3f4;
}

.k-pager-numbers .k-link {
    color: #03878c;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
    color: #03878c;
    background-color: #e6f3f4;
}

.refresh-icon {
    border: 1px solid #dedce4;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffffff;
}

.mat-drawer:not(.mat-drawer-side) {
    position: fixed;
}

.mat-drawer-backdrop {
    position: fixed !important;
}

.k-grid tr.disableRow {
    color: #adadad;
    pointer-events: none;
}

.delete-icon {
    height: 15px;
    cursor: pointer;
    margin-top: 20px;
    opacity: 0.65;
}

//material customized popup styles
.level-3-popup .mat-dialog-container {
    height: calc(100vh - 65px) !important;
    width: 100vw !important;
    margin-top: 64px;
    background: #f7f8fa;
    z-index: 9999;
    overflow: hidden;
    width: 97%;
}

.level-3-popup {
    max-width: 100% !important;
    width: 100% !important;
}

.split-inv-popup .mat-dialog-container {
    // height: calc(100vh - 200px) !important;
    width: 101vw;
    margin-top: 64px;
    background: #F7F7F7;
    z-index: 9999;
    margin: auto;
}

.split-inv-popup {
    max-width: 100% !important;
    background: #ffffff;
    width: 100% !important;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

.menu-template-container {
    position: unset !important;
}

.mat-drawer-backdrop {
    position: fixed !important;
    top: 44px;
}

.mat-drawer,
.mat-sidenav {
    top: 44px !important;
    z-index: 100000 !important;
}

.mat-drawer-content {
    overflow: hidden !important;
    position: inherit !important;
}

.mat-drawer-inner-container {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.material-icons.color_flag_warn {
    color: #e6492d;
}

.material-icons.color_flag_btn {
    color: #ffffff;
}

.p-10 {
    padding: 10px;
}

.dispute-dialog .mat-dialog-container {
    width: 700px;
    // overflow-y: scroll;
    overflow-x: hidden;
    min-height: 84vh;
    max-height: 100vh;
    border-radius: 8px;
    z-index: 1500;
}

.full-width-popup {
    margin-top: 100px;

    .mat-dialog-container {
        background: #F7F7F7;
        margin: auto;
        width: 100vw;
        height: 100vh;
        border-radius: 10px !important;
    }
}

.dispute-retailer-section .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 5px !important;
}

.dispute-retailer-section .mat-form-field.mat-focused .mat-form-field-ripple,
.dispute-retailer-section .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
    opacity: 0;
}

.inmar-layout-container .paddingTopCls {
    padding-top: 16px;
}

.mat-table .mat-header-row .mat-header-cell {
    height: 68px !important;
    align-items: start !important;
}

mat-header-cell:nth-child(1),
mat-cell:nth-child(1) {
    max-width: 32px !important;
}

.attachment-popup.ng-dropdown-panel.ng-select-bottom {
    top: 35.5% !important;
}

.memo-chat-tab {
    .mat-ink-bar {
        background-color: #303584 !important;
        height: 3px !important;
    }

    .mat-tab-label .mat-tab-label-content {
        font-family: 'Roboto-Regular' !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .mat-tab-label-active {
        color: #303584 !important;
    }

    .mat-tab-label.mat-tab-label-active {
        background-color: #F2F2F2 !important;
    }
}

.append-note .mat-dialog-container {
    min-height: 30vh;
    max-height: 100vh;
}

a {
    color: #007CBB !important;
    text-decoration: underline;
}

.downloadImg {
    filter: opacity(.5) drop-shadow(0 0 0 #007CBB);
}

.CreateIcon {
    filter: opacity(.5) drop-shadow(0 0 0 #03878C);
}

.IconSize {
    width: 20px !important;
    height: 20px !important;
}

.newPaymentTemplate {
    margin: 0 8px 0 0 !important;
}

.mat-paginator-container .mat-select-value {
    color: #757575 !important;
}

.mat-paginator-container .mat-paginator-page-size-select {
    margin: 15px 4px -10px 4px !important;
}

.cdk-overlay-container,
.ng-dropdown-panel {
    z-index: 10000 !important;
}

.mat-paginator-page-size .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 2.25em !important;
}

.cdk-overlay-container>div:nth-child(2) {
    z-index: 1000 !important;
}

::ng-deep .cdk-overlay-pane {
    position: absolute;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    min-width: fit-content;
    max-width: 360px;
    transform: none !important;
}

.mat-menu-container {
    max-width: 500px !important;

    .mat-menu-content {
        max-width: 500px !important;
    }
}

.filter-section {
    display: flex;
    border-bottom: 2px solid #ddd;
    padding: 0.5rem 1rem;
}

.dashboard-filter-header {
    font-family: bentonsans-Regular;
    color: #03878c;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

.dashboard-filter-form {
    border-bottom: 2px solid #ddd;
    background-color: #f7f7f7;
    padding: 1rem;

    .datePicker {
        width: 110px;
        padding-bottom: 8px;
        padding-left: 5px;
    }

    .mat-form-field-infix {
        border-top: 0px;
    }

    .mat-form-field-label {
        padding-left: 0.5rem;
    }

    .filter-date {
        .mat-form-field-infix {
            padding: 0px;
            border-top: 0px;
            background-color: #fff;
            width: 160px !important;
        }

        .mat-form-field-flex {
            padding: 0px;
        }
    }

    .date-error-msg {
        font-size: 12px;
        color: #d80b0b;
    }
}

.cli-datefield {
    .mat-form-field-flex {
        align-items: center;
    }
}

.dashboard-dropdown-label {
    .mat-form-field-flex {
        background-color: #fff;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
}

.dashboard-buttons {
    text-align: right;
    padding: 0.5rem 1rem;

    .reset-button {
        margin-right: 1rem;
    }

    .applyFilter-inactive {
        padding: 1.8px 0px;
        width: 100px;
        font-family: Roboto-Regular;
        margin-right: 20px !important;
        border-radius: 5px;
        font-size: 14px;
        color: rgb(137, 123, 176) !important;
        border-left: 1px solid #efefef;
        line-height: 27px;
        background-color: #dfe5e7;
    }

    .applyFilter-active {
        padding: 1.8px 0px;
        width: 100px;
        font-family: Roboto-Regular;
        margin-right: 20px !important;
        border-radius: 5px;
        font-size: 14px;
        color: #ffffff;
        background-color: #03878c;
        border-left: 1px solid #efefef;
        line-height: 27px;
        cursor: pointer;
    }
}

.filter-invert {
    filter: invert(1);
}

.fs-16px {
    font-size: 16px;
}